export interface CloudflareInsightsProps {
  cfBeacon?: string;
}

export const CloudflareInsights = ({ cfBeacon }: CloudflareInsightsProps) => {
  return (
    <>
      {cfBeacon && (
        <script
          defer
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon={`{"token": "${cfBeacon}"}`}
        />
      )}
    </>
  );
};
export default CloudflareInsights;
