import type { ComponentProps } from 'react';

type ClientOnlyScriptProps = ComponentProps<'script'>;

export const ClientOnlyScript = ({
  dangerouslySetInnerHTML,
}: ClientOnlyScriptProps) => {
  const html = dangerouslySetInnerHTML?.__html as string;

  if (!html) return null;

  return (
    <script
      ref={(ref) => {
        if (!ref) return;
        ref.innerHTML = html as string;
      }}
    />
  );
};
